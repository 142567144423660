import React from "react"
import SupportChat from "../CustomerSuccess/SupportChat"

const REVIEWS = [
  {
    key: "Mini Dreamers",
    name: "Mini Dreamers",
    date: "April 30, 2019",
    description:
      "Very good support and awesome app! Would highly recommend this to anyone who is trying to run a referral program.",
  },
  {
    key: "Hydro Kitty",
    name: "Hydro Kitty",
    date: "March 19, 2019",
    description:
      "Prompt customer service will help you through any issues you may encounter. Hadn't really promoted my referral campaign much but I’m seeing results as my store gains new customers.",
  },
  {
    key: "Trophy Skin",
    name: "Trophy Skin",
    date: "March 07, 2019",
    description:
      "Very easy to set up and use. My account rep, Tippy, is great at checking in with me every month and giving tips and ideas to improve our referral program",
  },
]

export default (props) => <SupportChat {...props} reviews={REVIEWS} />
