const INTEGRATION_LISTINGS = [
  {
    logo: "shopify",
    name: "Shopify",
    description: "Ecommerce platform",
    alt:
      "Referralcandy integrates with shopify - best shopify referral marketing app",
  },
  {
    logo: "woocommerce",
    name: "WooCommerce",
    description: "Ecommerce platform",
    alt:
      "Referralcandy integrates with woocommerce - best woocommerce referral marketing app",
  },
  {
    logo: "bigcommerce",
    name: "BigCommerce",
    description: "Ecommerce platform",
    alt:
      "Referralcandy integrates with bigcommerce - best bigcommerce referral marketing app",
  },
  {
    logo: "magento",
    name: "Magento",
    description: "Ecommerce platform",
    alt:
      "Referralcandy integrates with magento - best magento referral marketing app",
  },
  {
    logo: "mailchimp",
    name: "Mailchimp",
    description: "Email marketing",
    alt:
      "Referralcandy integrates with shopify - best mailchimp referral marketing app small business referrals",
  },
  {
    logo: "ga",
    name: "Google Analytics",
    description: "Analytics",
    alt:
      "Referralcandy integrates with google analytics - referral marketing traffic data referral analytics",
  },
  {
    logo: "klaviyo",
    name: "Klaviyo",
    description: "Email marketing",
    alt:
      "Referralcandy integrates with klaviyo - best klaviyo referral marketing app small business referrals",
  },
  {
    logo: "facebook",
    name: "Facebook Pixel",
    description: "Ads and Retargeting",
    alt:
      "Referralcandy integrates with facebook pixel - best facebook ads retargeting marketing app small business referrals",
  },
]

export default INTEGRATION_LISTINGS
