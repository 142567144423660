import React from "react"

import Layout from "../components/Layout"
import GradientBackground from "../components/Sections/GradientBackground"
import AboutIntegrations from "../components/Sections/AboutIntegrations"
import AboutCustomerSuccess from "../components/Sections/AboutCustomerSuccess"
import AboutReadOurBlog from "../components/Sections/AboutReadOurBlog"
import AboutRevenue from "../components/Sections/AboutRevenue"
import AboutOnlineStores from "../components/Sections/AboutOnlineStores"
import AboutMeetTheTeam from "../components/Sections/AboutMeetTheTeam"

const metadata = {
  title: "Why ReferralCandy",
}

const Page = (props) => {
  return (
    <Layout metadata={metadata} pathname={props.location.pathname}>
      <AboutOnlineStores />
      <GradientBackground>
        <AboutRevenue />
      </GradientBackground>
      <AboutIntegrations />
      <AboutCustomerSuccess />
      <AboutReadOurBlog />
      <AboutMeetTheTeam />
    </Layout>
  )
}

export default Page
