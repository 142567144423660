import React from "react"
import classnames from "classnames"
import css from "./index.module.scss"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import Grid from "../../Grid"
import Button from "../../Button"

const query = graphql`
  query {
    banner: file(relativePath: { eq: "images/about-blog.png" }) {
      childImageSharp {
        fixed(height: 360) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

const AboutReadOurBlog = () => {
  const { banner } = useStaticQuery(query)

  return (
    <Grid className={css.container} centeredClassName={css.centered}>
      <div className={css.text}>
        <h2>We think about this stuff for fun</h2>
        <p>
          Our special sauce is that we think about customer acquisition and
          referral marketing all the time. We use this knowledge to make your
          customer referral programs better.
        </p>
        <div>
          <Button className={css.desktop_button} href="/blog" target="_blank">
            Read Our Blog
          </Button>
        </div>
      </div>
      <div className={classnames(css.image, css.centered)}>
        <Img fixed={banner.childImageSharp.fixed} alt="Banner" />
      </div>
    </Grid>
  )
}

export default AboutReadOurBlog
