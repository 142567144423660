import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Grid from "../../Grid"
import css from "./index.module.scss"

import fontType from "../../../helpers/fontType.module.scss"

const OnlineStores = () => {
  const data = useStaticQuery(query)
  const logoKeys = Object.keys(data).filter(
    (name) => name.indexOf("logo_") >= 0
  )
  return (
    <Grid className={css.container} centeredClassName={css.centered}>
      <h1 className={css.center}>Why ReferralCandy</h1>
      <p className={`${fontType.b1}  ${css.subheader} ${css.centered}`}>
        ReferralCandy gets you more customers by incentivizing referrals.
        <br />
        Think of it as word-of-mouth marketing on steroids.
      </p>
      <div className={css.imageWrapper}>
        <div className={css.image}>
          <Img
            fluid={data["hero"].childImageSharp.fluid}
            alt="Why ReferralCandy"
          />
        </div>
      </div>
      <div className={css.content}>
        <h3 className={css.title}>
          We’ve powered referral programs for 30,000 brands and counting
        </h3>
        <ul className={css.store_logos}>
          {logoKeys.map((image) => (
            <li key={image}>
              <Img
                fixed={data[image].childImageSharp.fixed}
                alt={`Referralcandy pricing - ${image.replace(
                  "logo_",
                  ""
                )} successful referral marketing referral program software`}
              />
            </li>
          ))}
        </ul>
      </div>
    </Grid>
  )
}

export default OnlineStores

const query = graphql`
  query {
    hero: file(relativePath: { eq: "images/why-referralcandy-hero.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logo_adorebeauty: file(
      relativePath: {
        eq: "images/store_logos/Referralcandy pricing - adorebeauty successful referral marketing referral program software.png"
      }
    ) {
      childImageSharp {
        fixed(width: 170, height: 20) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    logo_ztylus: file(
      relativePath: {
        eq: "images/store_logos/Referralcandy pricing - ztylus successful referral marketing referral program software.png"
      }
    ) {
      childImageSharp {
        fixed(width: 99, height: 20) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    logo_hopsy: file(
      relativePath: {
        eq: "images/store_logos/Referralcandy pricing - hopsy successful referral marketing referral program software.png"
      }
    ) {
      childImageSharp {
        fixed(width: 53, height: 20) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    logo_flattummytea: file(
      relativePath: {
        eq: "images/store_logos/Referralcandy pricing - flat tummy tea successful referral marketing referral program software.png"
      }
    ) {
      childImageSharp {
        fixed(width: 167, height: 20) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`
