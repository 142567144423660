import React, { useState } from "react"
import css from "./index.module.scss"
import classnames from "classnames"
import Grid from "../../Grid"
import TEAM_MEMBER_DATA from "./teamMemberData"

const TeamMember = ({
  pictureClass,
  href = "https://www.referralcandy.com",
  name,
  jobTitle,
  sillyJobTitle,
}) => {
  const [isHovering, setIsHovering] = useState(false)
  return (
    <div className={classnames(css["team-mate"], css["js-team-mate"])}>
      <div
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        className={classnames(
          css[pictureClass],
          css["js-team-mate-pic"],
          css["team-mate-pic"]
        )}
      />
      <a
        href={href}
        style={{ textDecoration: "none" }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {name}
      </a>
      <br />
      <small className={css["js-team-mate-title"]}>
        {isHovering ? sillyJobTitle : jobTitle}
      </small>
    </div>
  )
}

export default () => {
  return (
    <Grid className={css.container} centeredClassName={css.centered}>
      <h2>Meet the team</h2>
      <p className={css.subtitle}>We’re choosy about who we work with.</p>
      <p className={css.subtitle}>
        The ReferralCandy team is made up of people who are driven by results,
        talented, fun, and hopelessly addicted to caffeine.
      </p>

      <div className={css.team_container}>
        {TEAM_MEMBER_DATA.map((data) => (
          <TeamMember {...data} key={data.name} />
        ))}
      </div>

      <h2>Our Investors & Advisors</h2>
      <div className={css.team_container}>
        <div className={classnames(css["team-mate"], css["js-team-mate"])}>
          <div
            className={classnames(
              css["piyush-chaplot-pic"],
              css["js-team-mate-pic"],
              css["team-mate-pic"]
            )}
          />
          Piyush Chaplot
          <br />
          <small>Innosight Ventures (Partner)</small>
        </div>
        <div className={classnames(css["team-mate"], css["js-team-mate"])}>
          <div
            className={classnames(
              css["william-klippgen-pic"],
              css["js-team-mate-pic"],
              css["team-mate-pic"]
            )}
          />
          William Klippgen
          <br />
          <small>BAF Spectrum (Founding Partner)</small>
        </div>
        <div className={classnames(css["team-mate"], css["js-team-mate"])}>
          <div
            className={classnames(
              css["ramanan-raghavendran-pic"],
              css["js-team-mate-pic"],
              css["team-mate-pic"]
            )}
          />
          Ramanan Raghavendran
          <br />
          <small>Amasia Associates (Founding Partner)</small>
        </div>
        <div className={classnames(css["team-mate"], css["js-team-mate"])}>
          <div
            className={classnames(
              css["john-kim-pic"],
              css["js-team-mate-pic"],
              css["team-mate-pic"]
            )}
          />
          John Kim
          <br />
          <small>Amasia Associates (Founding Partner)</small>
        </div>
        <div className={classnames(css["team-mate"], css["js-team-mate"])}>
          <div
            className={classnames(
              css["tom-clayton-pic"],
              css["js-team-mate-pic"],
              css["team-mate-pic"]
            )}
          />
          Tom Clayton
          <br />
          <small>VP of International Operations, Houzz</small>
        </div>
      </div>
    </Grid>
  )
}
