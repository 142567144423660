import React, { PureComponent } from "react"
import css from "./index.module.scss"

import Grid from "../../Grid"
import Button from "../../Button"
import SupportChat from "./SupportChat"

class CustomerSuccess extends PureComponent {
  render() {
    return (
      <Grid className={css.container} centeredClassName={css.centered}>
        <div className={css.image}>
          <SupportChat />
        </div>
        <div className={css.text}>
          <h2>We’re with you every step of the way</h2>
          <p>
            Our five-star customer success team are smart, friendly, and here to
            get you results
          </p>
          <Button
            purpose="secondary"
            shrinkwrap
            className={css.mobile_button}
            href="https://apps.shopify.com/referralcandy/reviews"
            target="_blank"
          >
            Read All Reviews
          </Button>
        </div>
      </Grid>
    )
  }
}

export default CustomerSuccess
