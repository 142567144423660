import React, { useState } from "react"
import css from "./index.module.scss"
import { FaSpinner } from "react-icons/fa"
import SignupLink from "../../SignupLink"
import Button from "../../Button"
import Grid from "../../Grid"
import useInterval from "../../../helpers/useInterval"

const useFetchAtInterval = (url, delay = 5000) => {
  const [state, setState] = useState({
    data: null,
    loading: true,
    error: null,
  })

  async function fetchData(url) {
    try {
      const response = await fetch(url)
      const json = await response.json()
      setState({
        data: json,
        loading: false,
        error: null,
      })
    } catch (error) {
      setState({
        data: null,
        loading: false,
        error: error,
      })
    }
  }

  useInterval(() => {
    fetchData(url)
  }, delay)

  return state
}

const FALLBACK_REVENUE_MAY_2019 = "$137,128,184.04"

export default () => {
  const { data, loading } = useFetchAtInterval(
    "https://my.referralcandy.com/stats.json"
  )

  const revenue =
    (data && data["total_referral_revenue"]) ||
    (loading ? <FaSpinner className={css.spin} /> : FALLBACK_REVENUE_MAY_2019)

  return (
    <Grid className={css.container} centeredClassName={css.centered}>
      <h2>We can help grow your business</h2>
      <p className={css.subtitle}>
        ReferralCandy has been running referral marketing campaigns every day
        since we started in 2009. We’ve gotten really good at increasing
        referral rates and growing sales for our customers.
      </p>

      <p className={css.revenue_value}>{revenue}</p>

      <p className={css.revenue_subheader}>in referral sales generated</p>
      <SignupLink el={Button} purpose="primary">
        Grow Your Sales
      </SignupLink>
    </Grid>
  )
}
