export default [
  {
    pictureClass: "dinesh-raju-pic",
    href: "https://www.linkedin.com/in/dineshraju",
    name: "Dinesh Raju",
    jobTitle: "CEO",
    sillyJobTitle: "Computer Repairman",
  },
  {
    pictureClass: "zach-cheng-pic",
    href: "https://www.linkedin.com/in/zachca",
    name: "Zach Cheng",
    jobTitle: "Executive Director",
    sillyJobTitle: "Tea Drinker",
  },
  {
    pictureClass: "lloyd-tan-pic",
    href: "https://www.linkedin.com/in/lloyd-tan-358aa440",
    name: "Lloyd Tan",
    jobTitle: "Finance & Operations Executive",
    sillyJobTitle: "Yogi Wannabe",
  },
  {
    pictureClass: "jaime-ricasata-pic",
    href: "https://www.linkedin.com/in/lloyd-tan-358aa440",
    name: "Jaime Ricasata",
    jobTitle: "Director of Customer Success",
    sillyJobTitle: "Mandalorian Bounty Hunter",
  },
  {
    pictureClass: "benjamin-george-pic",
    href: "https://www.linkedin.com/in/bengeorge21",
    name: "Benjamin George",
    jobTitle: "Head of Design",
    sillyJobTitle: "Mastermind of Mischief",
  },
  {
    pictureClass: "maythee-anegboonlap-pic",
    href: "https://twitter.com/llun",
    name: "Maythee Anegboonlap",
    jobTitle: "Product Engineer",
    sillyJobTitle: "Cyclist",
  },
  {
    pictureClass: "karen-manuel-pic",
    href: "https://www.linkedin.com/in/katherine-regina-abad-manuel-40b54b50",
    name: "Karen Manuel",
    jobTitle: "Customer Success Manager",
    sillyJobTitle: "Vampire Thaumaturge",
  },
  {
    pictureClass: "jared-tong-pic",
    href: "https://www.jaredtong.com",
    name: "Jared Tong",
    jobTitle: "Software Engineer",
    sillyJobTitle: "Dinosaur Trainer",
  },
  {
    pictureClass: "migz-ginete-pic",
    href: "https://www.linkedin.com/in/miguel-ginete-35469780",
    name: "Migz Ginete",
    jobTitle: "Customer Success Engineer",
    sillyJobTitle: "Classical Guitarist",
  },
  {
    pictureClass: "bryan-briones-pic",
    href: "https://www.linkedin.com/in/john-bryan-briones-61356580/",
    name: "Bryan Briones",
    jobTitle: "Senior Customer Success Manager",
    sillyJobTitle: "Mountain Man",
  },
  {
    pictureClass: "eleanor-tay-pic",
    href: "https://www.linkedin.com/in/zeppt",
    name: "Eleanor Tay",
    jobTitle: "Marketing Manager",
    sillyJobTitle: "Food Snob",
  },
  {
    pictureClass: "desmond-chua-pic",
    href: "https://desmondc.com",
    name: "Desmond Chua",
    jobTitle: "Culture and Customer Officer",
    sillyJobTitle: "Parkour Practitioner",
  },
  {
    pictureClass: "benz-jalali-pic",
    name: "Benz Jalali",
    jobTitle: "Customer Success Manager",
    sillyJobTitle: "Zi Hexenbiest",
  },
  {
    pictureClass: "tomas-koetz-pic",
    name: "Tomas Koetz",
    jobTitle: "Customer Success Manager",
    sillyJobTitle: "The Kraut",
  },
  {
    pictureClass: "jon-dolor-pic",
    name: "Jon Dolor",
    jobTitle: "Customer Success Manager",
    sillyJobTitle: "Candy luvin Blutbad",
  },
  {
    pictureClass: "adam-tolliday-pic",
    name: "Adam Tolliday",
    jobTitle: "Customer Success Manager",
    sillyJobTitle: "The Guvnah",
  },
  {
    pictureClass: "timothy-tan-pic",
    href: "http://linkedin.com/in/timothy-tan",
    name: "Timothy Tan",
    jobTitle: "Project Engineer",
    sillyJobTitle: "Board Game Jedi",
  },
  {
    pictureClass: "raul-galera-pic",
    name: "Raúl Galera",
    jobTitle: "Partner Manager",
    sillyJobTitle: "Webinar Jefe",
  },
  {
    pictureClass: "jason-nulla-pic",
    href: "https://www.linkedin.com/in/jason-nulla-45352174/",
    name: "Jason Nulla",
    jobTitle: "Customer Success Engineer",
    sillyJobTitle: "Candyminion Neophyte",
  },
  {
    pictureClass: "sherwin-jason-ang-pic",
    href:
      "https://www.linkedin.com/in/sherwin-jason-ang-%E9%99%B3%E4%BF%8A%E5%98%89-43a00b139/",
    name: "Sherwin Jason Ang",
    jobTitle: "Senior Customer Success Manager",
    sillyJobTitle: "Tea Infusiast",
  },
  {
    pictureClass: "daniel-goh-pic",
    href: "https://danielthedeveloper.com",
    name: "Daniel Goh",
    jobTitle: "Product Engineer",
    sillyJobTitle: "Mixed Rice Maniac",
  },
  {
    pictureClass: "lorraine-garcia-pic",
    name: "Lorraine Garcia",
    jobTitle: "Customer Success Manager",
    sillyJobTitle: "International Aesthetic Strategist",
  },
  {
    pictureClass: "darren-foong-pic",
    href: "https://medium.com/@darrendarren/",
    name: "Darren Foong",
    jobTitle: "Growth Marketing",
    sillyJobTitle: "Junior Content Engineer",
  },
  {
    pictureClass: "tan-shao-ming-pic",
    href: "https://www.linkedin.com/in/shao-ming-tan-42647551/",
    name: "Tan Shao Ming",
    jobTitle: "Product Engineer",
    sillyJobTitle: "Crazy Cat Dude",
  },
  {
    pictureClass: "jon-derek-benito-pic",
    name: "Jon Derek Benito",
    jobTitle: "Customer Success Manager",
    sillyJobTitle: "The Shoshin",
  },
  {
    pictureClass: "chen-shanhan-pic",
    href: "https://www.linkedin.com/in/chen-shanhan",
    name: "Chen Shanhan",
    jobTitle: "Product Engineer",
    sillyJobTitle: "Level 1 Adventurer",
  },
  {
    pictureClass: "marion-dwight-salagsag-pic",
    name: "Marion Dwight Salagsag",
    jobTitle: "Customer Success Manager",
    sillyJobTitle: "Customer Success Manager",
  },
  {
    pictureClass: "bobby-kariyatty-pic",
    href: "https://www.linkedin.com/in/bobbykariyatty/",
    name: "Bobby Kariyatty",
    jobTitle: "Sr. Product Designer",
    sillyJobTitle: "Master Foodie",
  },
  {
    pictureClass: "jun-torres-pic",
    name: "Jun Torres",
    jobTitle: "Customer Success Manager",
    sillyJobTitle: "The INFJ",
  },
  {
    pictureClass: "low-joo-tat-pic",
    href: "https://www.linkedin.com/in/joo-tat-low-2403b510a",
    name: "Low Joo Tat",
    jobTitle: "Product Designer",
    sillyJobTitle: "Professional Napper",
  },
  {
    pictureClass: "glenn-sunkel-pic",
    href: "https://sg.linkedin.com/in/glennsunkel",
    name: "Glenn Sunkel",
    jobTitle: "Engineering Manager",
    sillyJobTitle: "Chief Cat Herder",
  },
  {
    pictureClass: "hafiz-azmi-pic",
    href: "https://www.linkedin.com/in/hafiz-azmi-35661816a",
    name: "Hafiz Azmi",
    jobTitle: "Software Engineer (Intern)",
    sillyJobTitle: "Aspiring Farmer",
  },
  {
    pictureClass: "nina-espinosa-pic",
    name: "Nina Espinosa",
    jobTitle: "Operation Support Specialist",
    sillyJobTitle: "Captain Celery",
  },
  {
    pictureClass: "jc-fajardo-pic",
    name: "JC Fajardo",
    jobTitle: "Customer Success Manager",
    sillyJobTitle: "The Walking Endorphin",
  },
  {
    pictureClass: "mardie-sindayen-pic",
    name: "Mardie Sindayen",
    jobTitle: "Customer Success Manager",
    sillyJobTitle: "El Cucuy",
  },
  {
    pictureClass: "maria-nael-pic",
    name: "Maria Nael",
    jobTitle: "Customer Success Manager",
    sillyJobTitle: "Curious Inappropriate Robot",
  },
  {
    pictureClass: "rachel-leung-pic",
    href: "https://www.linkedin.com/in/fungyurachel",
    name: "Rachel Leung",
    jobTitle: "Product Designer",
    sillyJobTitle: "Wannabe Bassist",
  },
]
